<template>
  <div>
    <el-row>
      <el-col  v-for="(i, index) in list" :key="index" :span="4" :xs="8">
        <div @click="skip1(i)"><CardList :item="i" /></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CardList from '@/components/list/Card-List.vue';
import Expand from '@/request/expand.js';

export default {
  name: "external_app",
  components: {
    CardList
  },
  data() {
    return {
      ask: new Expand(),
      param: {"type": "external_app"},
      list: [],
    }
  },
  computed: {
    noMore() {
      return this.count >= 200
    },
    disabled() {
      return this.loading || this.noMore
    }
  },
  mounted() {
    this.ask.getOtherData(this.param).then(res => {
      res.msg.forEach(item => {
        let data  = JSON.parse(item['otherContent']);
        data['id'] = item['otherId']
        this.list.push(data)
      })
    })
  },
  methods: {
    skip1(id) {
      window.open('/expand/ChristmasTree/external?id=' + id['id'], "_blank");
    }
  }
}
</script>
<style>
ul{
  padding: 0;
  margin: 0;
  list-style: none;
}
</style>